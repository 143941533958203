<template>
  <a-card title="Recordings">
    <div class="p-4">
      <div class="flex flex-col lg:flex-row justify-between">
        <div class="flex-grow">
          <p class="text-black leading-6">
            Click to view seminar recordings
          </p>
        </div>
        <div>
          <router-link
            :to="{name: 'seminars.recordings.index'}"
            class="px-2 py-2 bg-primary hover:bg-primary-active text-white rounded text-sm"
          >
            <a-icon name="play" /> Recordings
          </router-link>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
export default {};
</script>