<template>
  <router-view :key="$route.path" />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters('conferences', ['current']),
    ...mapState('seminars', ['seminars', 'loaded'])
  },
  watch: {
    current: {
      immediate: true,
      handler: 'onCurrentConferenceChange'
    }
  },
  methods: {
    ...mapActions('seminars', ['all']),
    async onCurrentConferenceChange() {
      if (this.current) {
        await this.all();
      }
    }
  }
};
</script>