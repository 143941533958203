<template>
  <div>
    <div
      v-if="hasSeminars"
      class="grid grid-cols-1 gap-4 lg:grid-cols-2"
    >
      <recording-card
        v-for="seminar in rows"
        :key="seminar.id"
        :meeting="seminar"
      />
    </div>
    <div v-else-if="loaded">
      <a-alert type="info">
        There are no recordings to display
      </a-alert>
    </div>
  </div>
</template>

<script>
import { filter, isEmpty } from '@/utils/lodash';
import { mapState } from 'vuex';
import RecordingCard from '@/components/meetings/card/recording.vue';
export default {
  components: {
    RecordingCard
  },
  computed: {
    ...mapState('seminars', ['seminars', 'loaded']),
    rows() {
      return filter(this.seminars, seminar => !isEmpty(seminar.video));
    },
    hasSeminars() {
      return !isEmpty(this.rows);
    }
  }
};
</script>