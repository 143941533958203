<template>
  <div class="max-w-5xl py-6 lg:px-2">
    <div
      v-if="hasSeminars"
      class="space-y-4"
    >
      <dates-tabs
        v-model="selectedDate"
        :dates="dates"
        :selected="selectedDate"
        @change="onDateChange"
        @input="onDateModelChange"
      />
      <seminar-recordings-alert v-if="hasRecordings" />
      <seminar-card
        v-for="seminar in rows"
        :key="seminar.id"
        :meeting="seminar"
      />
    </div>
    <div v-else-if="loaded">
      <a-alert type="info">
        There are no seminars to display
      </a-alert>
    </div>
  </div>
</template>

<script>
import { filter, isEmpty } from '@/utils/lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import SeminarCard from '@/components/meetings/card/card.vue';
import DatesTabs from '@/components/ui/dates/dates-tabs.vue';
import SeminarRecordingsAlert from '@/components/seminars/recordings-alert.vue';
export default {
  components: {
    DatesTabs,
    SeminarCard,
    SeminarRecordingsAlert
  },
  data: () => ({
    selectedDate: ''
  }),
  computed: {
    ...mapState('seminars', ['seminars', 'loaded']),
    ...mapGetters('conferences', ['current']),
    ...mapGetters('seminars', ['grouped', 'dates']),
    rows() {
      if (!isEmpty(this.selectedDate)) {
        return filter(
          this.seminars,
          seminar => seminar.slot.day === this.selectedDate
        );
      }

      return this.seminars;
    },
    hasSeminars() {
      return !isEmpty(this.rows);
    },
    hasRecordings() {
      return !isEmpty(filter(this.rows, row => !isEmpty(row.video)));
    }
  },
  watch: {
    current: {
      immediate: true,
      handler: 'onCurrentConferenceChange'
    }
  },
  methods: {
    ...mapActions('seminars', ['all']),
    async onCurrentConferenceChange() {
      if (this.current) {
        await this.all();
      }
    },
    onDateChange(date) {
      if (this.selectedDate === date) {
        this.selectedDate = '';
      } else {
        this.selectedDate = date;
      }
    },
    onDateModelChange(event) {
      this.selectedDate = event.target.value;
    }
  }
};
</script>